import { ActionItemParams, useTrackClickedActionItem } from '@air/analytics';
import { Box, BoxProps } from '@air/zephyr';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, MouseEvent } from 'react';
import { useTheme } from 'styled-components';

import { NEW_TAB } from '~/constants/urls';

export interface AnchorProps extends BoxProps<'a'> {
  analytics?: ActionItemParams;
}

/** @comment do not export this component */
const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ analytics, onClick, tx, ...restOfProps }: AnchorProps, ref) => {
    const theme = useTheme();
    const { asPath } = useRouter();
    const { trackClickedActionItem } = useTrackClickedActionItem();

    const onLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) onClick(event);

      trackClickedActionItem({
        location: window.location.href,
        pathName: asPath,
        zone: 'product',
        ...analytics,
      });
    };

    return (
      <Box
        as="a"
        ref={ref}
        onClick={onLinkClick}
        {...restOfProps}
        tx={{
          borderRadius: 4,
          color: 'jay500',
          fontFamily: 'copy',
          fontSize: 16,
          fontWeight: 'semibold',
          lineHeight: 1.5,
          letterSpacing: '2%',
          wordBreak: 'break-word',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:focus, &:hover': {
            textDecoration: 'underline',
          },
          '&:focus': {
            outline: 'none',
          },
          '&.focus-visible': {
            boxShadow: `0 0 0 2px ${theme.colors.macaw100}`,
          },
          ...tx,
        }}
      />
    );
  },
);

Anchor.displayName = 'Anchor';

export type ExternalLinkProps = AnchorProps;

export const ExternalLink = ({
  analytics,
  href,
  rel = 'noopener noreferrer',
  target = NEW_TAB,
  ...restOfProps
}: ExternalLinkProps) => {
  return (
    <Anchor
      href={href}
      analytics={{
        destination: href,
        destinationType: 'external',
        ...analytics,
      }}
      rel={rel}
      target={target}
      {...restOfProps}
    />
  );
};

export interface InternalLinkProps
  extends Omit<LinkProps, 'onClick' | 'onMouseEnter'>,
    Omit<AnchorProps, 'as' | 'href'> {}

export const InternalLink = ({
  analytics,
  as,
  children,
  href,
  passHref = true,
  prefetch = undefined,
  replace = false,
  scroll = true,
  shallow = false,
  ...restOfProps
}: InternalLinkProps) => {
  return (
    <Link
      as={as}
      href={href}
      passHref={passHref}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      legacyBehavior
    >
      <Anchor
        analytics={{
          destination: as || href,
          destinationType: 'external',
          ...analytics,
        }}
        {...restOfProps}
      >
        {children}
      </Anchor>
    </Link>
  );
};
